<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <sriphat-logo-full /> -->
        <h2 class="brand-text text-primary ml-1">
          Health Information Exchange
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Welcome to Sriphat HIE
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start using
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Username"
                label-for="login-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  vid="username"
                  rules="required"
                >
                  <b-form-input
                    id="login-username"
                    v-model="username"
                    :state="errors.length > 0 ? false:null"
                    name="login-username"
                    placeholder="Username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <!-- <b-link :to="{name:'auth-forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link> -->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div class="mb-2 mt-2 d-flex justify-content-center">
                <small
                  v-if="invalidUserAndPass"
                  class="text-danger"
                ><b>Invalid username or password</b></small>
              </div>
              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group> -->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
            <div>
              New on our platform?
            </div>
            <div class="text-primary">
              Contract HRM
            </div>
          </b-card-text> -->
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import SriphatLogoFull from '@core/layouts/components/LogoSriphatFull.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    // SriphatLogoFull,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    // BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      username: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      invalidUserAndPass: false,
      // validation rules
      required,
      email,

      // Fix Menu
      userMenuNurse: [
        {
          title: 'Request',
          route: { name: 'RequestAudit' },
          icon: 'FolderPlusIcon',
          isBookmarked: false,
        },
        {
          title: 'RequestHistory',
          route: { name: 'RequestHistory' },
          icon: 'CheckSquareIcon',
          isBookmarked: false,
        },
        // {
        //   title: 'MedicalRecordAudit',
        //   route: { name: 'MedicalRecordAudit' },
        //   icon: 'TrelloIcon',
        //   isBookmarked: false,
        // },
        {
          title: 'addUserInfo',
          route: { name: 'addUserInfo' },
          icon: 'UserPlusIcon',
          isBookmarked: false,
        },
        {
          title: 'UsersList',
          route: { name: 'UsersList' },
          icon: 'ToolIcon',
          isBookmarked: false,
        },
        {
          title: 'TestTransferFile',
          route: { name: 'transferFile' },
          icon: 'MailIcon',
          isBookmarked: false,
        },
      ],
      userSWC: [
        {
          title: 'SWC',
          route: { name: 'SWC' },
          icon: 'CheckSquareIcon',
          isBookmarked: false,
        },
      ],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    // login() {
    //   // console.log('Login')
    //   // this.loginOrg()
    //   this.$http({
    //     url: '/api/Employee/UserSD',
    //     // url: 'http://192.168.100.147/api/Employee/UserSD',
    //     method: 'POST',
    //     data: {
    //       EmployeeCode: this.username,
    //       Password: this.password,
    //     },
    //   }).then(({ data }) => {
    //     // // console.log(data)
    //     const userRole = 'admin'
    //     // const { userData } = response.data
    //     // const { userDetail } = data.userDetail
    //     const userInfo = data.userDetail
    //     const userMenu = this.userMenuNurse // data.userMenu
    //     // // console.log(userDetail)
    //     const userData = {
    //       id: 1,
    //       fullName: userInfo.name,
    //       username: userInfo.username,
    //       avatar: '/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/13-small.d796bffd.png',
    //       email: '',
    //       role: userRole,
    //       ability: [{ action: 'manage', subject: 'all' }],
    //       menu: userMenu,
    //       extras: { },
    //     }
    //     useJwt.setToken(data.token)
    //     useJwt.setRefreshToken(data.token)
    //     localStorage.setItem('userData', JSON.stringify(userData))
    //     this.$ability.update(userData.ability)
    //     this.$router.replace(getHomeRouteForLoggedInUser(userRole))
    //       .then(() => {
    //         this.$toast({
    //           component: ToastificationContent,
    //           position: 'top-right',
    //           props: {
    //             title: `Welcome ${userData.fullName || userData.username}`,
    //             icon: 'CoffeeIcon',
    //             variant: 'success',
    //             text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
    //           },
    //         })
    //       })
    //       .catch(error => {
    //         this.$refs.loginForm.setErrors(error.response.data.error)
    //       })
    //   }).catch(error => {
    //     // console.log(error)
    //   })
    // },
    login() {
      this.$http({
        url: 'api/HIE/login',
        // url: 'http://192.168.100.147/api/Employee/UserSD',
        method: 'POST',
        test: 'test token',
        data: {
          username: this.username,
          password: this.password,
        },
      }).then(({ data }) => {
        let userMenu = []
        if (data === 'INCORRECT_USER' || data === 'INCORRECT_PASSWORD') {
          this.invalidUserAndPass = true
        } else {
          this.invalidUserAndPass = false
          const userRole = 'admin'
          if (data.userInfo.type === '8') {
            userMenu = this.userSWC
          } else {
            userMenu = data.userInfo.type === '9' ? this.userMenuNurse : [{
              title: 'MedicalRecordAudit',
              route: { name: 'MedicalRecordAudit' },
              icon: 'FolderIcon',
              isBookmarked: false,
            }]
          }
          const userData = {
            id: 1,
            fullName: data.userInfo.fullName,
            username: data.userInfo.username,
            avatar: '/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/13-small.d796bffd.png',
            email: '',
            role: userRole,
            ability: [{ action: 'manage', subject: 'all' }],
            menu: userMenu,
            extras: { },
            ...data.userInfo,
          }
          useJwt.setToken(data.token)
          useJwt.setRefreshToken(data.token)
          localStorage.setItem('userData', JSON.stringify(userData))
          this.$ability.update(userData.ability)
          this.$router.replace(getHomeRouteForLoggedInUser(data.userInfo.type))
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Welcome ${userData.fullName || userData.username}`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: `You have successfully logged in as ${userData.username}.`,
                },
              })
            })
            .catch(error => {
              this.$refs.loginForm.setErrors(error.response.data.error)
            })
        }
      }).catch(error => {
        this.$refs.loginForm.setErrors(error.response.data.error)
      })
      this.$http({
        url: 'http://api159.sriphat.com/api/LoginPatient/login-Employee',
        method: 'POST',
        data: {
          username: 'mistest',
          Password: '6502',
        },
      }).then(({ data }) => {
        localStorage.setItem('tokenFetchImage', data.token)
      })
    },
    loginOrg() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              const { userData } = response.data
              useJwt.setToken(response.data.accessToken)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$ability.update(userData.ability)

              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome ${userData.fullName || userData.username}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                    },
                  })
                })
            })
            .catch(error => {
              this.$refs.loginForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
